@import '../../assests/styles/brand-colors';

.process-review-sidebar {
    background-color: white;
    padding: 10px 30px;
    .alert-container {
        margin-bottom: 20px;
        .alert {
            float: none !important;
        }
    }
    .process-review-options {
        label {
            color: $dusk-lighter;
        }
    }
    .add-response-title {
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;

        strong {
            flex-grow: 1;
            text-align: center;
        }
    }
    .process-review-textarea {
        .icon {
            margin-top: 10px;
            margin-bottom: -25px;
            margin-right: 10px;
            z-index: 1 !important;
            position: relative;

            .icon-note {
                width: 20px;
                height: 19px;
            }
        }
        label {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            margin-bottom: 5px;
        }
        textarea {
            height: 176px;
            border: 1px solid $midnight-30;
            border-radius: 4px;
            z-index: 0 !important;
            padding: 25px 16px;
        }
    }

    .process-review-btn {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 4px;
        margin-top: 20px;

        button {
            width: 116px;
            margin-left: 10px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;

            border-radius: 4px;
        }
        .success-btn {
            background-color: $lake;
        }
        .cancel-btn {
            border: 1px solid $midnight-70;
            color: #003349;
        }
        .suppress-btn {
            background-color: #bd0024;
        }
    }
}

.process-review-empty {
    font-style: oblique !important;
    color: $gray-lightest;
}
