@import '../../assests/styles/brand-colors';
@import '../../assests/styles/font-styles';

.assign-to-cta {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    .assign-btn {
        width: 123px;
        height: 40px;
        font-weight: 600;
        &:disabled {
            background-color: $dusk-lighter;
        }
    }
    .auto-complete {
        border: 1px solid #b2c2c8;

        border-radius: 4px;
        height: 40px;
        width: 250px;

        fieldset {
            border: 0 !important;
        }
        input,
        div {
            border: 0 !important;
            margin-bottom: 0;
        }
        .MuiInputBase-root {
            padding-top: 2px !important;
        }
    }
    span {
        font-weight: bolder;
    }

    > * {
        margin: 10px;
    }
}
