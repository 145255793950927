@import '../../assests/styles/brand-colors';

.text-box {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 11px;

    .box-text {
        text-align: justify;
        width: 100%;
        white-space: pre-wrap;

        .collapsed-text-action {
            color: $lake;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .text-box-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .editor-button {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            gap: 5px;
            width: 100%;

            .button {
                > svg {
                    text-align: center;
                    margin-right: 8px;
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .title-editor-actions {
            padding: 4px;
            width: 100%;

            > input {
                border: 0.5px solid $midnight-30;
                border-radius: 4px;
                width: 100%;
            }
        }

        .text-editor-actions {
            width: 100%;
            padding: 4px;

            > textarea {
                border: 0.5px solid $midnight-30;
                border-radius: 4px;
            }
        }

        @media screen and (max-width: 968px) {
            .text-editor-actions {
                width: 70%;
            }
            .title-editor-actions {
                width: 70%;
            }
        }
    }
}

.editable {
    cursor: pointer;

    &:hover {
        border-left: 1px solid black;
    }
}

.text-editor {
    display: flex;
    flex-direction: column;
    > textarea {
        border: 0.5px solid $midnight-30;
        border-radius: 4px;
        height: 120px;
    }

    .count-ch {
        font-size: 12px;
        font-weight: 400;
    }
    .text-editor-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 4px;

        button {
            width: 116px;
            margin-left: 10px;
        }
    }
}

.error-btn {
    border-color: red;
}
