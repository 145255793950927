@import '../../assests/styles/brand-colors';

.count-pills {
    display: flex;
    flex-direction: row;
    align-items: center;

    .star-icon {
        margin-right: 15px;
        width: 28px !important;
        height: 26.93px !important;

        > path {
            fill: #fab60f;
        }
    }

    & div {
        margin-right: 10px;
    }

    .badge-container {
        span {
            cursor: pointer;
            background-color: rgba(255, 208, 0, 0.2);
            color: $dusk;
            font-weight: 400 !important;
            font-size: 16px !important;
            padding: 5px 20px;
        }
    }
    .active {
        span {
            background-color: #fab60f;
            opacity: 1 !important;
        }
    }
}
