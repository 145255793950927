@import '../../assests/styles/brand-colors';

.table-container {
    display: flex;
    background-color: gray;
}

.review-detail-icon-toolbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    span,
    button {
        cursor: pointer;
    }
    span {
        margin-right: 20px;
        margin-left: 20px;
        &:hover {
            opacity: 0.5;
        }
    }

    svg {
        height: 20px;
        width: 20px;

        > path {
            fill: $dusk;
        }
    }
    .selected-action {
        svg {
            > path {
                opacity: 0.3;
            }
        }
    }
}

.review-detail-answers-header-bar {
    align-items: center;
    border-bottom: 1px solid $midnight-30;

    .header-bar-headers {
        display: grid;
        grid-template-columns: 90px 50px 60px auto;
        gap: 10px;
        div {
            display: flex;
            justify-content: center;
            height: 60px;
            align-items: center;
        }
        .comment-header {
            justify-content: flex-start;
        }
    }
}

.review-detail-answers-list {
    .review-detail-answers-list-item {
        display: grid;
        grid-template-columns: 90px 50px 60px auto;
        gap: 10px;
        padding-right: 10px;
        padding-bottom: 5px;

        border-bottom: 1px solid $midnight-30;
        > div {
            margin-top: 20px;
            display: flex;
            justify-content: center;
            min-height: 34px;
            align-items: flex-start;

            .text-box {
                margin-top: 0 !important;
            }
        }
    }
}

.category {
    justify-content: flex-start !important;
    padding: 0 0 0 10px;
}
