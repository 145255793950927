@import '../../assests/styles/brand-colors';
@import '../../assests/styles/font-styles';

.table-list {
    .Mui-checked {
        color: #0078ab !important;
    }

    .MuiCheckbox-indeterminate {
        color: #0078ab !important;
    }

    .MuiTablePagination-selectLabel {
        margin-bottom: 0;
    }

    .MuiTablePagination-displayedRows {
        margin-bottom: 0;
    }
}

.right-btn {
    cursor: pointer;
    &:hover {
        opacity: 0.5;
    }
}

.link-style {
    color: $lake;
    text-decoration: underline $lake;

    &:hover {
        opacity: 0.7;
    }
    cursor: pointer;
}

.empty-modal {
    text-align: center;
    margin-top: 10px;
}

.auto-assign-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    btn-assign {
        max-width: 200px;
    }
}
