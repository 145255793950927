@import '../../assests/styles/brand-colors';

.cancel-btn {
    margin-right: 10px;
}

textarea {
    border: 0.5px solid $midnight-30;
    border-radius: 4px;
}

.error {
    color: $alertness;
}
.text-editor-actions {
    button {
        width: 116px;
        margin-left: 10px;
    }
}
