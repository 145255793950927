@import '../../assests/styles/brand-colors';
@import '../../assests/styles/font-styles';

.mt-detail-container {
    .detail {
        span {
            strong {
                margin-right: 3px;
            }
            svg {
                margin-right: 5px;
            }
        }
    }

    .mt-description {
        white-space: pre-wrap;
    }
}
