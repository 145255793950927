@import '~bootstrap/scss/bootstrap.scss';
@import 'assests/styles/_brand-colors.scss';
@import 'assests/styles/_font-styles.scss';

body {
    margin: 15px;
    //font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    //'Helvetica Neue', sans-serif;
    font-family: Public Sans, serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $gray-six;
}

.app-container {
    height: 100vh;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
li.MuiButtonBase-root {
    display: revert !important;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
    padding-left: 6px !important;
}
.MuiInputBase-root {
    padding-top: 4px !important;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
}

.link-style {
    color: $lake;
    text-decoration: underline $lake;
    display: flex;
    align-items: center;
    margin-left: 5px;
    svg {
        width: 15px !important;
        cursor: pointer;
        margin-left: 5px;
        > path {
            fill: $lake;
        }
    }

    &:hover {
        opacity: 0.7;
    }
    cursor: pointer;
}

.heading-card-24 {
    font-family: 'Public Sans', sans-serif !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 120% !important;
}

.body-regular {
    font-family: 'Public Sans', sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

.body-bold {
    font-family: 'Public Sans', sans-serif !important ;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 24px !important;
}

.body-small-bold {
    font-family: 'Public Sans', sans-serif !important ;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

.body-small-regular {
    font-family: 'Public Sans', sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

.body-tiny-bold {
    font-family: 'Public Sans', sans-serif !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 16px !important;
}

.body-tiny-regular {
    font-family: 'Public Sans', sans-serif !important  ;
    font-style: normal !important  ;
    font-weight: 700 !important ;
    font-size: 12px !important  ;
    line-height: 16px !important    ;
}
.hidden {
    display: none;
}
