@import '../../assests/styles/brand-colors';

.container-sidebar {
    border-left: 1px solid $midnight-30;
    display: flex;
    flex-direction: column;
    z-index: 100;
    height: 100%;
    background-color: white;

    .sidebar-title {
        .title {
            flex-grow: 1;
            text-align: center;

            flex-direction: row;
            align-items: center;
            .icon-close {
                margin-left: 5px;
                svg {
                    cursor: pointer;
                    height: 16px;
                    width: 16px;

                    > path {
                        fill: $dusk;
                    }
                }
            }
        }
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;
        border-bottom: 1px solid #dedbdb;
        padding: 12px 16px;
    }

    .btn-right {
        cursor: pointer;
        &:hover {
            opacity: 0.5;
        }
    }
    .add-response-submit-button {
        margin-top: 16px;
    }
}
