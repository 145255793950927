.loading {
    height: 65vh;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        width: 100px;
        height: 100px;
    }
}
