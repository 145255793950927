@import '../../assests/styles/brand-colors';
@import '../../assests/styles/font-styles';

.bulk-main {
    display: flex;
    flex-direction: column;
    align-items: center;

    & hr {
        width: 100%;
        margin: 25px;
    }

    .bulk-template {
        width: 90%;
        display: flex;
        gap: 24px;
        align-items: center;

        .bulk-box {
            width: 220px;
        }

        & svg {
            cursor: pointer;
            margin-left: 10px;
            height: 20px;
            width: 20px;
        }
    }

    .bulk-text {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
    }

    .bulk-button {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
        gap: 10px;

        width: 214px;
        height: 30px;

        background: #f5f7f8;

        border: 1px solid #668592;
        border-radius: 100px;

        & label {
            cursor: pointer;
            color: #003349;
        }

        & a {
            text-decoration: none;
            color: #003349;
        }

        & svg {
            margin: 0;
            height: 15px;
            width: 14px;
        }
    }

    .bulk-body {
        margin-bottom: 10px;
        width: 90%;
        display: flex;
        gap: 118px;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 1500px) {
            gap: 50px;
        }

        @media screen and (max-width: 1300px) {
            gap: 10px;
        }

        .bulk-submit {
            width: 180px;
            button {
                width: 180px;
                height: 40px;
                align-self: center;
            }
        }

        .bulk-note {
            display: flex;
            align-items: center;

            input {
                height: 48px;
                width: 761px;
                margin-left: 13px;
                @media screen and (max-width: 1750px) {
                    width: 500px;
                }
                @media screen and (max-width: 1500px) {
                    width: 300px;
                }
            }
        }

        .bulk-input {
            display: flex;
            flex-direction: column;
            width: 214px;

            .custom-file-input {
                margin-top: 20px;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;
            }

            .custom-file-input::file-selector-button {
                width: 0;
                visibility: hidden;
            }
        }
    }

    .bulk-history {
        width: 20%;

        .default {
            width: 100%;
            height: 100%;
            align-items: center;
            display: flex;
            padding-left: 15px;
        }
    }

    .link-cloud {
        display: none;
    }

    .bulk-message {
        width: 100%;
    }
}

.bulk-rules {
    width: 100%;
    padding: 0 10px 0 10px;
    display: flex;
    flex-direction: column;

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 10px;
    }

    .description {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
    }

    a:link {
        text-decoration: none;
    }

    & img {
        position: relative;
        margin-bottom: 15px;
        width: 100%;
    }
}
