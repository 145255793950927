.home {
    margin-top: 16px;
    display: block;
    .card-body {
        height: 100%;
        .queue-tabs {
            height: 100%;
        }
    }
}
.top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
}

.my-work-ctn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .check {
        margin-right: 10px;

        .MuiSwitch-colorSecondary.Mui-checked {
            color: #0078ab !important;
        }

        .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
            background-color: #0078ab !important ;
        }
    }
}
