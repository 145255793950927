@import '_brand-colors.scss';
textarea {
    min-height: 100px;
    border: 2px solid $black;
    padding: 8px;
    width: 100%;
}
.delimiter-textarea {
    margin-top: 16px;
}
.base-font-body {
    font-family: Public Sans, serif !important;
    font-size: 21px !important;
    line-height: 28px !important;
    letter-spacing: 0.5px !important;
    text-align: left;
}
.category-description {
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: 0 !important;
    font-weight: 600;
}
