.mt-close {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    .icon {
        margin-bottom: 20px;
    }

    .msg {
        text-align: center;
    }
}
