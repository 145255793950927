@import '../../assests/styles/brand-colors';
@import '../../assests/styles/font-styles';
.td-table-detail {
    border-bottom: 1px solid $gray-ten;
}

.td-table-detail-response {
    border-right: 1px solid $gray-ten;
}

.review-detail {
    padding: 8px;
    margin-top: 16px;
}

.textarea-response {
    border: 1px solid $midnight-30;
    border-radius: 4px;
    height: 150px;
}

.icon-align-right {
    text-align: right !important;
}

.icon-align-left {
    text-align: left !important;
    vertical-align: bottom !important;
}

.card-header-blank {
    justify-content: space-between;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: white !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
