.review-detail-banner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 32px;
    margin-left: 32px;
}

.review-detail-information {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 14px;
    margin-bottom: 16px;
    .text-name {
        text-transform: capitalize;
    }

    > div {
        flex-grow: 1;
    }

    .review-detail-unit-information {
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        border: 0;

        margin-right: 16px;
        width: 50%;
    }

    .review-detail-reservation-information {
        border: 0;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
        border-radius: 6px;
        width: 50%;
    }
}
