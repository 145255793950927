@import '../../assests/styles/brand-colors';

.open-review-actions {
    display: flex;
    justify-content: flex-end;
}

.open-review-msg {
    .info-text {
        text-align: justify-all;
    }

    .confirmation-text {
        font-weight: 600;
    }
}

.cancel-btn {
    margin-right: 10px;
}

.processing-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        width: 100px;
        height: 100px;
    }
}

.open-review-error {
    word-break: normal !important;
}
