@import '../../assests/styles/brand-colors';
@import '../../assests/styles/font-styles';

.icon-point {
    background-color: transparent !important;
    box-shadow: none !important;
    margin: 0 0 !important;
    margin-top: 10px !important;
    margin-bottom: 5px !important;
    padding: 0 0 !important;
    display: flex;
    justify-content: center;
    width: 25px !important;
    height: 25px !important;
}

.title-data {
    max-width: 25vw;
}

.collapsed-text {
    text-align: justify;
    margin-bottom: 0;
    width: 100%;

    .collapsed-text-action {
        color: $lake;

        &:hover {
            cursor: pointer;
        }
    }
}
