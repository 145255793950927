@import '../../assests/styles/brand-colors';
@import '../../assests/styles/font-styles';

.search-review {
    display: flex;
    align-items: center;
    gap: 15px;

    .search-category {
        width: 207px !important;
    }
    .search-input {
        height: 40px;
        width: 292px;
    }
    .auto-complete {
        width: 292px;
        border: 1px solid #b2c2c8;
        border-radius: 4px;
        height: 40px;
        fieldset {
            border: 0 !important;
        }
        input,
        div {
            text-align: inherit;
            border: 0 !important;
            margin-bottom: 0;
        }
        .MuiInputBase-root {
            padding-top: 2px !important;
        }
    }
    .search-btn {
        height: 40px;
        width: 123px;
        border-radius: 4px;
    }
    .line {
        border-left: 1px solid #b2c2c8;
        height: 40px;
        margin-left: 60px;
    }
}
