.processing-spinner-assign {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        width: 100px;
        height: 100px;
    }
}

.warning-msg {
    word-break: normal !important;
}
