.refresh-review-actions {
    display: flex;
    justify-content: flex-end;
}

.refresh-review-msg {
    .info-text {
    }

    .confirmation-text {
        font-weight: 400;
        word-break: normal !important;
    }
}

.refresh-cancel-btn {
    margin-right: 10px;
}

.refresh-processing-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        width: 100px;
        height: 100px;
    }
}
