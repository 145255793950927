@import '../../assests/styles/brand-colors';
@import '../../assests/styles/font-styles';

.textarea-maintenance {
    border: 1px solid $midnight-30;
    border-radius: 4px;
    height: 393px;
    max-height: 400px;
}

.invalid {
    border: 2px solid red;
}

.check-type {
    margin-right: 15px;
}

.due-date {
    border-radius: 4px !important;
    > div > input {
        text-align: left !important;
        padding-left: 10px !important;
        font-family: 'Public Sans' !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 20px !important;
    }
}
