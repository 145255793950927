@import '../../assests/styles/brand-colors';
@import '../../assests/styles/font-styles';

.summary-container {
    display: flex;
    //justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 25px;

    .summary-card {
        &:first-child {
            margin-right: 16px;
        }
        border-radius: 4px;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
        width: 50%;
        background: $default-10;
        border: $default-40 solid 0.5px;
        padding: 10px;

        .summary-title {
            padding-left: 3px;
        }
        .summary-detail {
            margin-top: 10px;
            padding-left: 10px;
            white-space: break-spaces;
        }
    }
}
