@import '../../assests/styles/brand-colors';
@import '../../assests/styles/font-styles';

.link {
    color: $lake;
    text-decoration: underline $lake;

    &:hover {
        opacity: 0.7;
    }
    cursor: pointer;
}
