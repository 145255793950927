@import '../../assests/styles/brand-colors';
@import '../../assests/styles/font-styles';

.textarea-add {
    border: 1px solid $midnight-30;
    border-radius: 4px;
    height: 148px;
    margin-top: 10px;
    max-height: 400px;
}

.invalid {
    border: 2px solid red;
}

.note-button {
    border: 1px solid $midnight-70;
    border-radius: 4px;
    padding: 8px 24px;
    color: $midnight;

    gap: 8px;
    &:hover {
        opacity: 0.5 !important;
        order: 1px solid $midnight-70;
    }
}

.error-msg {
    color: red;
}
